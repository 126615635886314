// This manages access to runtime configuration as set in a combination of separate file
// deployed with this application, and configuration interpolated into the HTML by Auth0.

import React from 'react';
import useAxios from 'axios-hooks';

declare global {
  interface Window {
    config: any;
  }
}

// What we need to receive via Auth0 interpolating @@config@@ in the login form HTML.
// Auth0 may give us additional config.

export interface Auth0RuntimeConfig {
  auth0Domain: string;
  auth0Tenant: string;
  authorizationServer: {
    issuer: string;
  };
  callbackURL: string;
  clientID: string;
  extraParams: {
    bb_login_params?: string; // Preferred way to specify Foundations tenant ID.
    login_hint?: string; // Deprecated Foundations tenant ID configuration.
    protocol?: string; // Type of login occurring; used to detect Snowflake SAML logins.
  };
  internalOptions: {
    [key: string]: any; // Here be dragons--these are undocumented.
  };
}

export interface AppConfigProps {
  auth0?: Auth0RuntimeConfig;
  bbApi?: {
    // These are keyed by AWS region.
    ssoBaseUrl: Record<string, string>;
    tenancyBaseUrl: Record<string, string>;
    readerAccountManagementBaseUrl: Record<string, string>;
  };
  bbUrls?: {
    help: string;
    privacyPolicy: string;
    signInEntrypoint: string;
    terms: string;
  };
  error?: Error | null;
  loading: boolean;
}

export const AppConfigContext = React.createContext<AppConfigProps>({ loading: true });

export const useAppConfigContext = () => React.useContext(AppConfigContext);

export const AppConfigProvider: React.FunctionComponent = ({ children }) => {
  const [auth0, setAuth0] = React.useState<Auth0RuntimeConfig>();
  const [runtimeError, setRuntimeError] = React.useState<Error>();
  const [{ data, loading, error: axiosError }] = useAxios(
    {
      url: `${process.env.PUBLIC_URL}/config/config${process.env.NODE_ENV === 'development' ? '.dev' : ''}.json`,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  );

  // This has to be retrieved via a global set by the deployed HTML because
  // that's the only thing that differs per regional deployment. This value is
  // set either by the local dev server or the CDK build process.

  const appRegion: string = (window as any).signInUiRegion;

  React.useEffect(() => {
    if (!appRegion) {
      setRuntimeError(new Error('App region is not set'));
    } else if (typeof appRegion !== 'string') {
      setRuntimeError(new Error(`App region is not a string: "${appRegion}"`));
    }
  }, [appRegion]);

  // Because the runtime config is interpolated for us into the HTML, we should
  // be able to see it immediately.

  React.useEffect(() => {
    if (window.config && typeof window.config === 'string') {
      try {
        const rawConfig = JSON.parse(
          decodeURIComponent(escape(atob(window.config))),
        );
        setAuth0({
          authorizationServer: rawConfig.authorizationServer,
          auth0Domain: rawConfig.auth0Domain,
          auth0Tenant: rawConfig.auth0Tenant,
          callbackURL: rawConfig.callbackURL,
          clientID: rawConfig.clientID,
          extraParams: rawConfig.extraParams,
          internalOptions: rawConfig.internalOptions,
        });
      } catch (e) {
        setRuntimeError(e);
      }
    } else {
      setRuntimeError(new Error('Required runtime configuration not present'));
    }
  }, []);

  let context: AppConfigProps = { error: runtimeError || axiosError, loading: (runtimeError || axiosError) ? false : loading };

  if (appRegion && data) {
    const ssoBaseUrl = data.bbApi?.ssoBaseUrl?.[appRegion];
    const tenancyBaseUrl = data.bbApi?.tenancyBaseUrl?.[appRegion];
    const readerAccountManagementBaseUrl = data.bbApi?.readerAccountManagementBaseUrl?.[appRegion];

    if (!ssoBaseUrl) {
      throw new Error(`No SSO API URL configured for region "${appRegion}"`);
    }

    if (!tenancyBaseUrl) {
      throw new Error(`No tenancy API URL configured for region "${appRegion}"`);
    }

    if (!tenancyBaseUrl) {
      throw new Error(`No tenancy API URL configured for region "${appRegion}"`);
    }

    if (!readerAccountManagementBaseUrl) {
      throw new Error(`No Reader account management API URL configured for region "${appRegion}"`);
    }

    context = {
      auth0,
      bbApi: { ssoBaseUrl, tenancyBaseUrl, readerAccountManagementBaseUrl },
      bbUrls: data.bbUrls,
      ...context,
    };
  }

  return <AppConfigContext.Provider value={context}>{children}</AppConfigContext.Provider>;
};
