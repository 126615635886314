// Retrieves the Tenant identifier for a Reader Account via an API call.

import useAxios from 'axios-hooks';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { GetReaderAccountTenantIdResult } from './useReaderAccountTenantIdConversion.types';

export function useReaderAccountTenantIdConversion(): GetReaderAccountTenantIdResult {
  const {
    bbApi,
    error: appConfigError,
    loading: configLoading,
  } = useAppConfigContext();
  const [{ data, error, loading: loadingTenantId }, fetch] = useAxios('', {
    manual: true,
  });

  const fetchBbTenantId = (readerAccountFullLocator: string) => {
    if (!configLoading && !appConfigError) {
      fetch({
        url: `${bbApi?.readerAccountManagementBaseUrl}/readerAccounts/${readerAccountFullLocator}/tenantId`,
      }).catch((e: Error) => console.warn(e));
    }
  };

  if (loadingTenantId) {
    return { loading: true, fetchBbTenantId };
  }

  // The HTTP request failed or the response we received was bad.
  if (appConfigError || error) {
    return {
      loadError: appConfigError || error,
      loading: false,
      fetchBbTenantId,
    };
  }

  // The HTTP request succeeded and there is a Tenant identifier.
  return {
    bbTenantId: data?.tenantId,
    loading: false,
    fetchBbTenantId,
  };
}
