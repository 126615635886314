// A set of email and password fields.

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ChevronLeft } from '@bb-ui/icons/dist/small/ChevronLeft';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { Auth0Error } from 'auth0-js';
import { useAuth0 } from 'hooks/useAuth0';
import { AuthConnection } from 'hooks/useAuthConnections.types';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useBbTenantContext } from 'contexts/BbTenantContext';

const useStyles = makeStyles((theme: Theme) => createStyles({
  backButton: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    paddingTop: theme.spacing(4),
    textAlign: 'left',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
  footerLinks: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    display: 'grid',
    fontSize: '12px',
    gridGap: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  idpName: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  root: {
    display: 'grid',
    gridGap: theme.spacing(3),
    margin: '0 auto',

    [theme.breakpoints.up('sm')]: {
      width: '360px',
    },
  },
  signInFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}));

export interface UserPassSignInProps {
  connection: AuthConnection;
  onBack?: () => void;
}

export type SignInError = Error & Auth0Error;

export type SignInResult = 'ok' | 'failure' | 'too_many_attempts';

export const UserPassSignIn: React.FunctionComponent<UserPassSignInProps> = ({ connection, onBack }) => {
  const styles = useStyles();
  const { bbUrls } = useAppConfigContext();
  const { tenant } = useBbTenantContext();
  const { login } = useAuth0();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [signingIn, setSigningIn] = React.useState(false);
  const [signInResult, setSignInResult] = React.useState<SignInResult>('ok');

  // Reset error when the user changes any field after an error.

  React.useEffect(() => {
    if (signInResult !== 'ok' && (username || password)) {
      setSignInResult('ok');
    }
  }, [password, signInResult, username]);

  const commonFieldProps = {
    floatingLabel: true,
    fullWidth: true,
  };
  const classes = useStyles();
  const { t } = useTranslation();
  const canSubmit = login && !signingIn && username.trim() !== '' && password.trim() !== '';

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSigningIn(true);

    login(username, password, connection.externalId).catch((e: SignInError) => {
      setUsername('');
      setPassword('');
      setSigningIn(false);
      setSignInResult(e?.code === 'too_many_attempts' && connection.type === 'LearnConnector' ? 'too_many_attempts' : 'failure');
    });

    // If we succeed, Auth0 redirects the user.
  };

  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit}
      data-testid="user-pass-sign-in"
    >
      <Typography variant="h2" className={styles.idpName}>{t('userPassSignIn.prompt', { connectionName: connection.displayName })}</Typography>
      <div className={styles.signInFields}>
        <TextField
          id="user-pass-sign-in-username"
          label={t('userPassSignIn.username')}
          onChange={e => setUsername(e.target.value)}
          value={username}
          {...commonFieldProps}
        />
        <TextField
          id="user-pass-sign-in-password"
          label={t('userPassSignIn.password')}
          onChange={e => setPassword(e.target.value)}
          type="password"
          value={password}
          {...commonFieldProps}
        />
      </div>
      {signInResult !== 'ok' && (
        <Typography className={styles.errorMessage}>
          {t(
            signInResult === 'too_many_attempts' ?
              'userPassSignIn.accountLocked' :
              'userPassSignIn.signInFailed',
          )}
        </Typography>
      )}
      <PrimaryButton disabled={!canSubmit} fullWidth={true} type="submit" data-testid="user-pass-sign-in-submit">
        {signingIn ? <CircularProgress size="small" /> : t('userPassSignIn.signIn')}
      </PrimaryButton>
      <div className={styles.footerLinks}>
        {bbUrls?.signInEntrypoint && <Link href={bbUrls.signInEntrypoint}>{t('connectionPicker.changeTenant')}</Link>}
        {tenant?.privacyPolicyLink && <Link href={tenant.privacyPolicyLink}>{t('userPassSignIn.tenantPrivacyPolicy')}</Link>}
        {connection?.changePasswordLink && <Link href={connection.changePasswordLink}>{t('userPassSignIn.forgotCredentials')}</Link>}
      </div>
      {onBack &&
        <div className={styles.backButton}>
          <IconButton onClick={onBack}>
            <ChevronLeft />
            <Typography component="span" variant="inherit">
              {t('global.back')}
            </Typography>
          </IconButton>
        </div>
      }
    </form>
  );
};
