import React from 'react';

const LogoStacked = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 146 86"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="anthology-logo-stacked"
  >
    <title id="anthology-logo-stacked">Anthology</title>
    <path d="M68.985 7.37305H64.9089V39.5138H68.985V7.37305Z" fill="#8CC63F" />
    <path d="M75.0361 0H70.96V39.5136H75.0361V0Z" fill="#FFC20E" />
    <path d="M81.0912 7.37305H77.0151V39.5138H81.0912V7.37305Z" fill="#F15A24" />
    <path d="M87.1425 16.501H83.0664V39.5094H87.1425V16.501Z" fill="#662D91" />
    <path d="M62.9338 16.501H58.8577V39.5094H62.9338V16.501Z" fill="#00B1EB" />
    <path
      d="M85.716 55.9332V72.9569C85.716 75.1532 85.2771 75.9983 84.3383 76.4351V76.7372H89.9872V76.4351C89.0443 75.9942 88.6095 75.1532 88.6095 72.9569V51.3364L84.2367 52.8224V53.1613C84.9764 53.4634 85.716 54.3084 85.716 55.9332Z"
      fill="black"
    />
    <path
      d="M51.2784 63.4979C52.4894 62.6529 54.4401 61.5057 56.3908 61.5057C58.6097 61.5057 59.6175 62.6202 59.6175 65.2901V72.9609C59.6175 75.1573 59.2152 76.0023 58.2724 76.4391V76.7412H63.8887V76.4391C62.9459 75.9982 62.511 75.1573 62.511 72.9609V64.347C62.511 61.4404 61.5682 59.2114 58.0367 59.2114C55.6471 59.2114 53.3266 60.53 51.2784 62.759V51.3405L46.9056 52.8265V53.1653C47.6453 53.4674 48.3849 54.3125 48.3849 55.9373V72.965C48.3849 75.1614 47.946 76.0064 47.0072 76.4432V76.7453H52.6235V76.4432C51.6807 76.0023 51.2784 75.1614 51.2784 72.965V63.4979Z"
      fill="black"
    />
    <path
      d="M40.7163 76.876C38.2292 76.876 37.0181 75.4226 37.0181 72.3159H37.0222V60.9995H34.3969V60.1871C36.5183 59.342 38.2657 57.8234 39.1395 54.9167H39.9116V59.7829L44.8208 59.4441L44.5201 60.9995H39.9116V71.8424C39.9116 74.1734 40.5496 75.1205 42.3337 75.1205C43.0733 75.1205 44.0162 74.9858 44.9224 74.5817L45.089 74.8838C44.0812 76.0309 42.3296 76.876 40.7163 76.876Z"
      fill="black"
    />
    <path
      d="M31.9058 72.9569V64.343C31.9058 61.4363 30.9304 59.2073 27.4314 59.2073C25.0418 59.2073 22.7213 60.5259 20.6731 62.7549V59.1379L16.3004 60.6239V60.926C16.975 61.2648 17.7796 61.8364 17.7796 63.6286V72.9528C17.7796 75.1491 17.3407 75.9942 16.402 76.431V76.7331H22.0183V76.431C21.0755 75.9901 20.6731 75.1491 20.6731 72.9528V63.4938C21.8842 62.6161 23.8348 61.5016 25.7855 61.5016C28.0044 61.5016 29.0123 62.6161 29.0123 65.286V72.9569C29.0123 75.1532 28.6099 75.9983 27.6671 76.4351V76.7372H33.2834V76.4351C32.3772 75.9942 31.9058 75.1532 31.9058 72.9569Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6794 73.2263V64.6451C12.6794 61.571 11.2001 59.2073 6.59166 59.2073C3.42994 59.2073 0.739631 60.7627 0.739631 63.057C0.739631 64.1715 1.51177 64.9839 2.5562 64.9839C3.60062 64.9839 4.40528 64.2083 4.40528 63.1591C4.40528 62.314 3.86884 61.7058 3.12921 61.4363C3.76724 60.5586 4.84418 60.0524 6.32344 60.0524C8.74553 60.0524 9.78589 61.571 9.78589 64.5471V67.4864C9.11128 67.3844 8.13595 67.3191 7.3638 67.3191C2.75533 67.3191 0 69.3807 0 72.6221C0 75.4267 2.01976 77.0801 4.77509 77.0801C7.1281 77.0801 8.91215 75.8309 9.85498 74.5123C10.1232 76.1003 11.0985 76.9454 12.4437 76.9454C13.7888 76.9454 14.5976 76.4718 15.2356 75.7615V75.4226H14.7317C13.0858 75.4226 12.6794 74.8838 12.6794 73.2263ZM9.78589 73.5284C8.94466 74.6429 7.59951 75.488 6.15276 75.488C4.33619 75.488 3.09263 74.2714 3.09263 72.3445C3.09263 69.8787 4.94171 68.258 8.37165 68.258C8.87558 68.258 9.3795 68.3274 9.78589 68.3927V73.5284Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.6728 68.4295C65.6728 62.959 68.9686 59.2073 74.2476 59.2073H74.2517C79.3641 59.2073 82.4567 62.9591 82.4567 67.9927C82.4567 73.4998 78.8561 77.1128 73.8453 77.1128C68.8345 77.1128 65.6728 73.4631 65.6728 68.4295ZM68.8345 67.6906C68.8345 72.5527 70.9193 76.2024 74.3167 76.2024H74.3208C77.1777 76.2024 79.2625 73.3978 79.2625 68.5683C79.2625 63.4 77.0761 60.0891 73.7803 60.0891C70.8543 60.0891 68.8345 63.1632 68.8345 67.6906Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.8728 68.4295C91.8728 62.959 95.1686 59.2073 100.448 59.2073H100.452C105.564 59.2073 108.657 62.9591 108.657 67.9927C108.657 73.4998 105.056 77.1128 100.045 77.1128C95.0345 77.1128 91.8728 73.4631 91.8728 68.4295ZM95.0345 67.6906C95.0345 72.5527 97.1193 76.2024 100.517 76.2024C103.374 76.2024 105.458 73.3978 105.458 68.5683C105.458 63.4 103.272 60.0891 99.9762 60.0891C97.0543 60.0891 95.0345 63.1632 95.0345 67.6906Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.067 57.5172C123.653 57.5172 122.511 58.599 121.905 60.2524C120.861 59.5747 119.516 59.2032 118.004 59.2032C113.867 59.2032 111.042 61.7711 111.042 65.2493C111.042 67.9192 112.522 69.8093 114.777 70.6217C113.802 71.1606 112.221 72.4139 112.221 74.1693C112.221 75.2838 112.826 76.1289 113.903 76.6024C111.819 77.2801 110.27 78.9662 110.27 80.8931C110.27 83.3915 112.489 85.1837 116.492 85.1837C122.344 85.1837 126.481 81.2972 126.481 77.8517C126.481 75.2512 124.868 74.2387 121.267 74.2387H116.862C115.248 74.2387 114.541 73.7325 114.541 72.7201C114.541 72.0791 114.944 71.4382 115.517 70.8299C116.09 70.9646 116.728 71.034 117.366 71.034C121.434 71.034 124.36 68.5356 124.36 64.9513C124.36 63.0938 123.653 61.6078 122.409 60.5912C122.779 60.0156 123.25 59.6441 123.787 59.5421C124.022 60.2198 124.595 60.6892 125.368 60.6892C126.343 60.6892 126.879 60.0115 126.879 59.1338C126.879 58.2561 126.205 57.5131 125.063 57.5131L125.067 57.5172ZM114.675 76.8433C115.212 76.978 115.854 77.0474 116.557 77.0474H121.129C123.149 77.0474 124.124 77.6231 124.124 79.1417C124.124 82.1137 121.231 83.9059 117.601 83.9059C114.745 83.9059 112.757 82.5546 112.757 80.1541C112.757 78.7335 113.529 77.5863 114.675 76.8433ZM117.703 70.189C115.618 70.189 114.005 68.16 114.005 64.9513C114.005 62.0813 115.553 60.0197 117.703 60.0197C119.853 60.0197 121.401 62.3508 121.401 65.2207C121.401 68.0906 119.788 70.189 117.703 70.189Z"
      fill="black"
    />
    <path
      d="M140.819 59.8809V59.5788L140.823 59.5747H146V59.8768C144.687 60.5545 143.781 61.9058 142.367 65.486C142.367 65.486 140.794 69.7032 138.396 75.6798C135.999 81.6565 134.186 85.2163 130.858 85.2163C129.411 85.2163 128.265 84.608 127.493 83.763V83.4609C130.72 83.2894 135.137 82.3464 136.986 77.0066L131.504 64.3389C130.492 62.0772 129.785 60.7586 128.509 59.8809V59.5788H134.731V59.8809C133.418 60.7913 133.755 62.3467 134.662 64.543L138.327 73.3937L141.119 65.5554C142.095 62.7508 142.131 60.6933 140.819 59.8809Z"
      fill="black"
    />
  </svg>
);

export default LogoStacked;
