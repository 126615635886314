import React from 'react';
import { useAppConfigContext } from 'contexts/AppConfigContext';

export function useLoginParams() {
  const { auth0: auth0Config, loading: configLoading } = useAppConfigContext();
  const params = React.useMemo<Record<string, string> | undefined>(
    () => {
      if (!configLoading) {
        if (auth0Config) {
          if (auth0Config.extraParams.bb_login_params) {
            try {
              return JSON.parse(
                window.atob(auth0Config.extraParams.bb_login_params),
              );
            } catch (err) {
              // Do nothing -- undefined is returned.
            }
          }
        } else {
          throw new Error('Required runtime configuration not present');
        }
      }
    },
    [auth0Config, configLoading],
  );

  return { loading: configLoading ?? params !== undefined, params };
}
