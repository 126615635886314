import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { ExternalSignIn } from 'components/ExternalSignIn';
import { useAuthConnections } from 'hooks/useAuthConnections';
import { useAuth0 } from 'hooks/useAuth0';
import { UserPassSignIn } from 'components/UserPassSignIn';
import { ErrorMessage } from 'components/ErrorMessage';

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      textAlign: 'center',
    },
  })
);

export const SignIn: React.FunctionComponent = (props) => {
  const styles = useStyles(props);
  const { bbTenantId, learnConnector } = useAuth0();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isSupport = searchParams.get('isSupport') === 'true';
  const { connections, loading, loadError } = useAuthConnections({
    tenantId: bbTenantId,
    learnConnectorHostname: learnConnector?.host,
    isSupport,
  });
  const history = useHistory();
  const { idpId } = useParams<{ idpId: string }>();
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={styles.loading}>
        <CircularProgress data-testid="login-loading" />
      </div>
    );
  }

  // Bad connection response from the API.

  if (loadError) {
    return <ErrorMessage title={t('global.fetchError')} message={loadError.message} />;
  }

  // The tenant has no connections.

  if (!idpId || !connections || connections.length === 0) {
    return <ErrorMessage title={t('global.genericError')} message={t('signIn.noConnection')} />;
  }

  const connection = connections.find((c) => c.id === idpId);

  if (!connection) {
    return <ErrorMessage title={t('global.genericError')} message={t('signIn.noConnection')} />;
  }

  // Default to <UserPassSignIn />.

  if (['AzureAD', 'SAML', 'Social'].includes(connection.type)) {
    return <ExternalSignIn connection={connection} />;
  }

  const onBack = () => history.push('/connection-picker');

  return (
    <UserPassSignIn connection={connection} onBack={connections.length > 1 ? onBack : undefined} />
  );
};
