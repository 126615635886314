// This manages access to auth0.js on behalf of a React component. It normally
// shouldn't be needed by regular React apps; we use it here because we are
// interacting directly with the authentication API.
//
// Each component calling useAuth0() will receive its own instance of the
// WebAuth class, so it is theoretically possible for them to interact with the
// Auth0 API independently.

import React from 'react';
import { AuthorizeOptions, WebAuth } from 'auth0-js';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useHost } from './useHost';
import { useBbTenantId } from './useBbTenantId';

export function useAuth0() {
  const { auth0: auth0Config, loading: configLoading } = useAppConfigContext();

  // We only need to do initial setup once per component that uses this hook. We
  // can't do global setup, because we want to offer each component a separate
  // WebAuth instance.

  const [inited, setInited] = React.useState(false);

  // accessToken is the pre-existing access token, if any, from a previous
  // successful authentication. It is immutable, since it is provided by Auth0
  // itself.

  const [accessToken, setAccessToken] = React.useState<string>();

  // webAuth is our Auth0 instance. It can't be changed by consumers. See
  // https://auth0.com/docs/libraries/auth0js/v9 for info on this object.

  const [webAuth, setWebAuth] = React.useState<WebAuth>();

  // Host is the hostname of the app being logged into. It is JSON-encoded in
  // the extraParams.login_hint (not loginHint!) property that Auth0's config
  // provides to hosted login pages. It also can't be changed by consumers.

  const host = useHost();

  // bbTenantId is the Blackboard (not Auth0!) tenant ID that the calling
  // application wants to authenticate against.

  const bbTenantId = useBbTenantId();

  if (!inited && !configLoading) {
    const storedToken = window.localStorage.getItem('access_token');

    if (storedToken) {
      setAccessToken(storedToken);
    }

    // Have to type params as any because Auth0's definitions don't include the
    // overrides property.
    //
    // See https://auth0.com/docs/custom-domains/configure-features-to-use-custom-domains#universal-login

    if (auth0Config) {
      setWebAuth(new WebAuth({
        clientID: auth0Config.clientID,
        domain: auth0Config.auth0Domain,
        overrides: {
          __tenant: auth0Config.auth0Tenant,
          __token_issuer: auth0Config.authorizationServer?.issuer,
        },
        redirectUri: auth0Config.callbackURL,
        responseType: 'code',
        ...auth0Config.internalOptions,
      } as any));
    } else {
      throw new Error('Required runtime configuration not present');
    }

    setInited(true);
  }

  return {
    accessToken,
    bbTenantId,
    webAuth,
    learnConnector: {
      host,
    },
    authorize: (options: AuthorizeOptions) => {
      if (!webAuth) {
        throw new Error('Auth0 client could not be initialized');
      }

      return webAuth.authorize(options);
    },
    login: (login: string, password: string, realm: string): Promise<any> => {
      if (!webAuth) {
        throw new Error('Auth0 client could not be initialized');
      }

      return new Promise((resolve, reject) => {
        let username = login;

        if (host) {
          // The host was specified. We need to propagate the
          // host value up to Auth0.  To do so, we'll concatenate host onto
          // username.  TenantId will be concatenated on as well.
          // The three will be sent using the username parameter during auth0 authentication.
          // The Learn as IdP Auth0:connection login script will split username
          // back into its individual values. e.g. bsimpson::::_BB_::::123::::_BB_::::monument.edu
          // When no host is specified, username is sent as is to Auth0.

          username = [login, bbTenantId, host].join('::::_BB_::::');
        }

        webAuth.login({ password, realm, username }, (error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      });
    },
  };
}
