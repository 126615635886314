import * as React from 'react';
import { Redirect } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@bb-ui/react-library';
import { useLoginParams } from 'hooks/useLoginParams';

export const LoginParamsCheck: React.FunctionComponent = () => {
  const { loading, params: { idpId, isSupport } = {} } = useLoginParams();
  const { t } = useTranslation();

  if (loading) {
    return <Box textAlign="center"><CircularProgress aria-label={t('global.loading')} /></Box>;
  }

  if (idpId) {
    return <Redirect to={`/sign-in/${idpId}${isSupport ? '?isSupport=true' : ''}`} />;
  }

  return <Redirect to="/connection-picker" />;
};
