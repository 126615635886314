// Manages lookup of information related to the tenant the user is logging into,
// as set in the Auth0 login_hint (see README.md for more explanation).

import React from 'react';
import useAxios from 'axios-hooks';
import { useAuth0 } from 'hooks/useAuth0';
import { useAppConfigContext } from './AppConfigContext';

// API response at /tenants/discoverable.

export type DiscoverableTenant = {
  description: string;
  id: string;
  logoLink: string;
  logoDisplayName: string;
  name: string;
  privacyPolicyLink: string;
  technicalSupportLink: string;
  uriComponent: string;
};

export interface BbTenantProps {
  error?: Error | null;
  loading: boolean;
  tenant?: DiscoverableTenant;
}

export const BbTenantContext = React.createContext<BbTenantProps>({ error: null, loading: true });

export const useBbTenantContext = () => React.useContext(BbTenantContext);

export const BbTenantProvider: React.FunctionComponent = ({ children }) => {
  const { error: configError, loading: configLoading, bbApi } = useAppConfigContext();
  const { bbTenantId } = useAuth0();
  const [{ data, loading: axiosLoading, error: axiosError }, fetch] = useAxios(`${bbApi?.tenancyBaseUrl}/tenants/discoverable`, { manual: true });
  const [fetched, setFetched] = React.useState(false);

  React.useEffect(() => {
    if (!fetched && bbApi?.tenancyBaseUrl) {
      fetch().catch(e => console.warn(e));
      setFetched(true);
    }
  }, [fetched, bbApi, fetch]);

  const context = {
    error: configError || axiosError,
    loading: configLoading || axiosLoading,
    tenant: data && Array.isArray(data) && data.find((tenant: DiscoverableTenant) => tenant.id === bbTenantId),
  };

  return <BbTenantContext.Provider value={context}>{children}</BbTenantContext.Provider>;
};
