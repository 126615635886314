import * as React from 'react';
import classnames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import orderBy from 'lodash.orderby';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { Radio } from '@bb-ui/react-library/dist/components/Radio';
import { RadioGroup } from '@bb-ui/react-library/dist/components/RadioGroup';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useAuth0 } from 'hooks/useAuth0';
import { useAuthConnections } from 'hooks/useAuthConnections';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'components/ErrorMessage';
import { CustomRadioSelectedIcon } from './CustomRadioSelectedIcon';

const radioHeight = 80;
const radioGap = 12;

const useStyles = makeStyles((theme: Theme) => createStyles({
  footerLinks: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    fontSize: '12px',
    paddingTop: theme.spacing(4),
  },
  loading: {
    textAlign: 'center',
  },
  prompt: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
  },
  radio: {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.background.b4}`,
    borderRadius: '4px',
    margin: 0,
    minHeight: radioHeight,
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  radioChecked: {
    backgroundColor: '#f2f8fc',
    border: `2px solid ${theme.palette.focus.main}`,
  },
  radioGroup: {
    display: 'grid',
    gridGap: radioGap,
    maxHeight: `${3 * radioHeight + 2 * radioGap}px`,
    overflowY: 'auto',
  },
  root: {
    display: 'grid',
    gridGap: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
  },
  tenantNameText: {
    paddingBottom: '30px',
    textAlign: 'center',
  },
}));

export const ConnectionPicker: React.FunctionComponent = (props) => {
  const styles = useStyles(props);
  const { bbUrls } = useAppConfigContext();
  const { bbTenantId, learnConnector } = useAuth0();
  const { connections, loading, loadError } = useAuthConnections({
    tenantId: bbTenantId,
    learnConnectorHostname: learnConnector?.host,
  });
  const [selectedConnection, setSelectedConnection] = React.useState<string>();
  const [redirecting, setRedirecting] = React.useState(false);
  const { t } = useTranslation();

  if (loading) {
    return <div className={styles.loading}><CircularProgress data-testid="connection-picker-loading" /></div>;
  }

  // Bad connection response from the API.

  if (loadError) {
    return <ErrorMessage title={t('global.fetchError')} message={loadError.message} />;
  }

  // The tenant has no connections.

  if (!connections || connections.length === 0) {
    return <ErrorMessage title={t('global.genericError')} message={t('connectionPicker.noIdps')} />;
  }

  // If there's no choice to make, move the user forward.

  if (connections.length === 1) {
    return <Redirect to={`/sign-in/${connections[0].id}`} />;
  }

  // The user chose their connection.

  if (redirecting && selectedConnection) {
    return <Redirect to={`/sign-in/${selectedConnection}`} />;
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (!selectedConnection) {
      return;
    }

    setRedirecting(true);
  }

  return (
    <form onSubmit={handleSubmit} className={styles.root}>
      <Typography variant="h2" className={styles.prompt}>{t('connectionPicker.prompt')}</Typography>
      <RadioGroup aria-label={t('connectionPicker.prompt')} name="login-connection" onChange={e => setSelectedConnection(e.target.value)} className={styles.radioGroup}>
        {orderBy(connections, 'displayName').map(connection => <FormControlLabel
          value={connection.id}
          checked={selectedConnection === connection.id}
          control={<Radio checkedIcon={<CustomRadioSelectedIcon />} />}
          key={connection.id}
          label={t('connectionPicker.optionLabel', { connectionName: connection.displayName })}
          className={classnames(styles.radio, { [styles.radioChecked]: selectedConnection === connection.id })}
        />)}
      </RadioGroup>
      <PrimaryButton disabled={selectedConnection === undefined} type="submit" data-testid="connection-picker-submit">{t('connectionPicker.continue')}</PrimaryButton>
      { bbUrls?.signInEntrypoint && <div className={styles.footerLinks}><Link href={bbUrls.signInEntrypoint}>{t('connectionPicker.changeTenant')}</Link></div>}
    </form>);
};
