// This tries to login via AzureAD or SAML via a redirect, but displays a button
// as a fallback in case the browser blocks it.

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { useAuth0 } from 'hooks/useAuth0';
import { AuthConnection } from 'hooks/useAuthConnections.types';

export interface ExternalSignInProps {
  connection: AuthConnection;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  loadingMessage: {
    marginBottom: theme.spacing(4),
    marginTop: '16px',
  },
  manualLink: {
    font: 'inherit',
  },
  manualMessage: {
    display: 'inline-flex',
    fontSize: '14px',
    marginTop: '40px',
  },
  manualPrompt: {
    '&::after': {
      content: '"\\00a0"', // &nbsp;
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
}));

export const ExternalSignIn: React.FunctionComponent<ExternalSignInProps> = ({
  connection,
}) => {
  const styles = useStyles();
  const { authorize } = useAuth0();
  const [started, setStarted] = React.useState(false);
  const authorizeCall = React.useCallback(() => authorize({ connection: connection.externalId }), [authorize, connection]);
  const { t } = useTranslation();
  const canAuthorize = authorize && connection?.externalId;

  React.useEffect(
    () => {
      if (!started && canAuthorize) {
        setStarted(true);
        authorizeCall();
      }
    },
    [authorizeCall, canAuthorize, started],
  );

  return (
    <div data-testid="external-sign-in" className={styles.root}>
      <CircularProgress label={t('externalSignIn.loading')} data-testid="external-sign-in-loading" />
      {canAuthorize &&
        <div className={styles.manualMessage}>
          <Typography className={styles.manualPrompt}>{t('externalSignIn.manualPrompt')}</Typography>
          <Link className={styles.manualLink} component="button" onClick={authorizeCall}>{t('externalSignIn.manualAction')}</Link>
        </div>}
    </div>
  );
};
