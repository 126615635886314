import React from 'react';
import { useAppConfigContext } from 'contexts/AppConfigContext';

export function useHost() {
  const { auth0: auth0Config, loading: configLoading } = useAppConfigContext();

  const [host, setHost] = React.useState<string>();
  const [inited, setInited] = React.useState<boolean>(false);

  if (!inited && !configLoading) {
    if (auth0Config) {
      let host: string | undefined;

      // Try parsing bbLoginParams first, then fall back to login_hint.

      if (auth0Config.extraParams.bb_login_params) {
        try {
          // This value is base64-encoded; login_hint is not.

          const parsedBbLoginParams = JSON.parse(
            window.atob(auth0Config.extraParams.bb_login_params),
          );

          host = parsedBbLoginParams?.learnConnector?.host;
        } catch (err) {
          // Do nothing -- try using login_hint below.
        }
      }

      if (!host && auth0Config.extraParams.login_hint) {
        try {
          const parsedLoginHint = JSON.parse(
            auth0Config.extraParams.login_hint,
          );

          host = parsedLoginHint?.learnConnector?.host;
        } catch (err) {
          // Do nothing -- it is up to consumers to handle this problem.
        }
      }

      if (host) {
        setHost(host);
      }
    } else {
      throw new Error('Required runtime configuration not present');
    }

    setInited(true);
  }

  return host;
}
