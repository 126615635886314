/**
 * TODO: https://jira.bbpd.io/browse/PLAT-2555
 * - Update from hook to context w/ hook to prevent api call on each use
 * - Render `loadError` in context provider (don't render children when error occurs)
 * - Remove individual `loadError` ErrorMessage components from SignIn.tsx and ConnectionPicker.tsx
 */

// Retrieves possible connections for a particular tenant (e.g. SAML, Google,
// and so on) and Learn connector hostname via an API call.
//
// This allows for an undefined bbTenantId so that it can cooperate with
// useAuth0, which returns an undefined value for tenant when it isn't provided
// by Auth0.

import React from 'react';
import useAxios from 'axios-hooks';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { AuthConnectionsResult, UseAuthConnectionsOptions } from './useAuthConnections.types';

type IdentityProvider = Record<string, string> & { brokerData?: Record<string, string> };

const supportedBrokerTypes = ['Auth0'];

export function useAuthConnections(options: UseAuthConnectionsOptions = {}): AuthConnectionsResult {
  const { bbApi, error: appConfigError } = useAppConfigContext();
  const [fetched, setFetched] = React.useState(false);
  const { tenantId, learnConnectorHostname: hostname, isSupport } = options;
  const params = {
    hostname,
    isSupport: isSupport === true ? 'true' : undefined,
  };
  const [{ data, error, loading }, fetch] = useAxios(
    {
      url: `${bbApi?.ssoBaseUrl}/tenants/${tenantId}/identityProviders`,
      params: Object.fromEntries(Object.entries(params).filter(([, v]) => v !== undefined)),
    },
    { manual: true }
  );

  React.useEffect(() => {
    if (!fetched && tenantId && bbApi) {
      setFetched(true);
      fetch().catch((e) => console.warn(e));
    }
  }, [bbApi, fetch, fetched, tenantId]);

  // We're either waiting for all needed props or waiting for a response.

  if (loading || (!fetched && !appConfigError)) {
    return { loading: true };
  }

  // The HTTP request failed or the response we received was bad.

  if (appConfigError || error || !data.results) {
    return { loadError: appConfigError || error, loading: false };
  }

  // We have retrieved connections. We map the data to remove any extraneous
  // properties.

  return {
    connections: data.results
      .filter(
        (data: IdentityProvider) =>
          !data.brokerType || supportedBrokerTypes.includes(data.brokerType)
      )
      .map((data: IdentityProvider) => ({
        changePasswordLink: data.changePasswordLink,
        displayName: data.displayName,
        // Legacy API uses `name` instead of `externalId`. Remove this coalescing
        // once we are no longer using legacy APIs on any tier.
        // Govcloud SAML IDP for snowflake has the connection name defined in data.brokerData.identityProviderName
        externalId: data.externalId ?? data.name ?? data.brokerData?.identityProviderName,
        id: data.id,
        description: data.description,
        type: data.type,
      })),
    loading: false,
  };
}
