import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { Logo } from './Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      bottom: 0,
      borderTop: `1px solid ${theme.palette.secondary.main}`,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: '20px 20px',
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        padding: '0 60px',
      },
    },
    logo: {
      height: '20px',
      marginRight: '18px',
      width: 'auto',
    },
    start: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    startItem: {
      [theme.breakpoints.up('sm')]: {
        marginRight: '20px',
      },
    },
    links: {
      display: 'flex',
      gap: '20px',
      [theme.breakpoints.up('sm')]: {
        gap: '30px',
      },
    },
    link: {
      color: theme.palette.primary.main,
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 'auto',
      lineHeight: 1.6,
    },
  }),
);

export const Footer: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const styles = useStyles(props);

  return (
    <footer className={styles.root}>
      <div className={styles.start}>
        <div>
          {' '}
          {/* Needed to keep logo from overflowing footer on Safari, even as recent as 13 */}
          <Logo className={styles.logo} />
        </div>
        <Typography className={styles.startItem}>
          ©{new Date().getFullYear()} {t('footer.copyright')}
        </Typography>
      </div>
      <div className={styles.start}>
        <div className={styles.links}>
          <Link className={styles.link} href={t('links:terms')}>
            {t('footer.terms')}
          </Link>
          <Link className={styles.link} href={t('links:privacyPolicy')}>
            {t('footer.privacy')}
          </Link>
          <Link className={styles.link} href={t('links:help')}>
            {t('footer.help')}
          </Link>
        </div>
      </div>
    </footer>
  );
};
