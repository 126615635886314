import React from 'react';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useReaderAccountTenantIdConversion } from './useReaderAccountTenantIdConversion';

// There are currently three(!) ways the Blackboard tenant ID may be determined.
// In order of precedence:
//
// 1. It is JSON-encoded, then base64-encoded in the extraParams.bb_login_params
//    property provided by Auth0's config.
//
// 2. (deprecated) It is JSON-encoded in the extramParams.login_hint property
//    provided by Auth0's config. This is *not* base64-encoded.
//
// 3. It can be retrieved from the bbdata reader account management service.
//    This only happens for SAML signins coming from Snowflake.
//
// If none of these options can be used to determine the tenant Id, then this
// hook will return `undefined` as the tenantId value.

const snowflakeBaseUrl = 'snowflakecomputing.com';

export function useBbTenantId() {
  const [inited, setInited] = React.useState(false);
  const { auth0: auth0Config, loading: configLoading } = useAppConfigContext();

  // Tenant ID either from bb_login_params or login_hint. It can't be changed by
  // consumers.

  const [bbTenantIdFromConfig, setBbTenantIdFromConfig] =
    React.useState<string>();

  // bbTenantIntId is a tenant ID that that is determined by the passing another tenant identifier
  // (like a Snowflake reader account) to the Tenant Integration ID service.

  const {
    bbTenantId: bbTenantIdFromReaderAccountLocator,
    loading: loadingTenantIdFromReaderAccountLocator,
    loadError: loadTenantIdFromReaderAccountLocatorError,
    fetchBbTenantId,
  } = useReaderAccountTenantIdConversion();

  if (!inited && !configLoading) {
    if (!auth0Config) {
      throw new Error('Required runtime configuration not present');
    }

    if (
      auth0Config.extraParams?.protocol === 'samlp' &&
      auth0Config.callbackURL?.includes(snowflakeBaseUrl)
    ) {
      // This is a SAML login coming from Snowflake.

      const url = new URL(auth0Config.callbackURL);
      const readerAccountFullLocator = url.hostname
        .replace(`.${snowflakeBaseUrl}`, '')
        .split('.')
        .map((v, index) => (index === 0 ? v.toUpperCase() : v.toLowerCase()))
        .join('.');

      fetchBbTenantId(readerAccountFullLocator);
    } else {
      let tenantId: string | undefined;

      // Try bb_login_params first. This value is base64-encoded.

      if (auth0Config.extraParams.bb_login_params) {
        try {
          tenantId = JSON.parse(
            window.atob(auth0Config.extraParams.bb_login_params)
          ).tenantId;
        } catch (err) {
          // Do nothing--we'll try login_hint below.
        }
      }

      // If that didn't work, try login_hint. This value is *not* base64-encoded.

      if (!tenantId && auth0Config.extraParams.login_hint) {
        try {
          tenantId = JSON.parse(auth0Config.extraParams.login_hint).tenantId;
        } catch (err) {
          // Do nothing--we'll return undefined to the consumer.
        }
      }

      if (tenantId) {
        setBbTenantIdFromConfig(tenantId);
      }
    }
    setInited(true);
  }

  if (loadTenantIdFromReaderAccountLocatorError) {
    throw new Error('Failed to retrieve the tenant ID');
  }

  if (configLoading || loadingTenantIdFromReaderAccountLocator) {
    return undefined;
  }

  return bbTenantIdFromReaderAccountLocator || bbTenantIdFromConfig;
}
