import React from 'react';

export const Logo = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 82 20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="anthology-logo"
  >
    <title id="anthology-logo">Anthology</title>
    <path d="M4.14706 3.00537H2.47852V16.1062H4.14706V3.00537Z" fill="#8CC63F" />
    <path d="M6.62557 0H4.95703V16.1061H6.62557V0Z" fill="#FFC20E" />
    <path d="M9.10409 3.00537H7.43555V16.1062H9.10409V3.00537Z" fill="#F15A24" />
    <path d="M11.5821 6.72656H9.91357V16.106H11.5821V6.72656Z" fill="#662D91" />
    <path d="M1.66854 6.72656H0V16.106H1.66854V6.72656Z" fill="#00B1EB" />
    <path
      d="M38.2173 14.3681C38.2173 15.3768 38.4029 15.7648 38.8379 15.9654V16.1042H36.2469V15.9654C36.6819 15.763 36.8825 15.3768 36.8825 14.3681V6.54849C36.8825 5.8042 36.5413 5.41613 36.2001 5.27552V5.11991L38.2173 4.4375V9.68122C39.1641 8.6576 40.2346 8.05205 41.335 8.05205C42.9642 8.05205 43.3992 9.07567 43.3992 10.4105V14.3663C43.3992 15.3749 43.6016 15.763 44.0347 15.9636V16.1023H41.4438V15.9636C41.8787 15.7611 42.0643 15.3749 42.0643 14.3663V10.8436C42.0643 9.61748 41.5994 9.10567 40.5758 9.10567C39.6759 9.10567 38.776 9.63248 38.2173 10.0206V14.3681Z"
      fill="black"
    />
    <path
      d="M33.3448 16.1679C34.0891 16.1679 34.8971 15.7798 35.362 15.253H35.3602L35.2833 15.1143C34.8652 15.2999 34.4303 15.3617 34.0891 15.3617C33.2661 15.3617 32.9717 14.9268 32.9717 13.8563V8.87693H35.0977L35.2364 8.16265L32.9717 8.31825V6.08353H32.6155C32.2124 7.41837 31.4044 8.11578 30.4277 8.50386V8.87693H31.6388V14.0738C31.6388 15.5005 32.1974 16.1679 33.3448 16.1679Z"
      fill="black"
    />
    <path
      d="M24.0985 14.3681C24.0985 15.3768 24.2841 15.7648 24.719 15.9654V16.1042H22.1281V15.9654C22.563 15.763 22.7636 15.3768 22.7636 14.3681V10.0862C22.7636 9.26315 22.3905 9.00068 22.0812 8.84507V8.70634L24.0985 8.02393V9.68497C25.0452 8.66135 26.1157 8.0558 27.2162 8.0558C28.8304 8.0558 29.2803 9.07942 29.2803 10.4143V14.37C29.2803 15.3786 29.4978 15.7667 29.9159 15.9673V16.106H27.3249V15.9673C27.7599 15.7648 27.9455 15.3786 27.9455 14.37V10.8473C27.9455 9.62123 27.4805 9.10942 26.4569 9.10942C25.557 9.10942 24.6571 9.62123 24.0985 10.0243V14.3681Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.59 15.5005V15.6561C21.2957 15.9823 20.9076 16.1998 20.3021 16.1998C19.6965 16.1998 19.2316 15.8117 19.1078 15.0824C18.6729 15.6879 17.8517 16.2616 16.7644 16.2616C15.4914 16.2616 14.5615 15.5005 14.5615 14.2144C14.5615 12.7258 15.8345 11.7791 17.9586 11.7791C18.3148 11.7791 18.7647 11.8109 19.076 11.8559V10.5061C19.076 9.14127 18.5941 8.44198 17.4787 8.44198C16.7962 8.44198 16.2994 8.67445 16.0051 9.07753C16.3463 9.20126 16.5938 9.4806 16.5938 9.86868C16.5938 10.3505 16.2207 10.7067 15.7408 10.7067C15.2608 10.7067 14.9027 10.3336 14.9027 9.82181C14.9027 8.76631 16.1438 8.0539 17.6024 8.0539C19.7284 8.0539 20.4108 9.13939 20.4108 10.5511V14.4918C20.4108 15.253 20.5964 15.5005 21.3575 15.5005H21.59ZM19.076 12.274C18.8904 12.2421 18.6579 12.2121 18.4235 12.2121C16.8412 12.2121 15.9882 12.9564 15.9882 14.0888C15.9882 14.9737 16.5619 15.5323 17.3999 15.5323C18.0673 15.5323 18.6879 15.1443 19.076 14.6325V12.274Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.8577 12.289C44.8577 14.6003 46.3159 16.2762 48.627 16.2766C51.0018 16.2762 52.5986 14.6171 52.5986 12.0884C52.5986 9.77681 51.2488 8.0539 48.8135 8.0539C46.3782 8.0539 44.8577 9.77494 44.8577 12.289ZM51.1251 12.3527C51.1251 14.5706 50.1633 15.8586 48.8454 15.8586H48.8435C47.2762 15.8586 46.3144 14.1844 46.3144 11.9497C46.3144 9.87055 47.2462 8.45885 48.596 8.45885C50.1165 8.45885 51.1251 9.97929 51.1251 12.3527Z"
      fill="black"
    />
    <path
      d="M55.4389 14.3681C55.4389 15.3768 55.6414 15.7648 56.0745 15.9654V16.1042H53.4685V15.9654C53.9035 15.763 54.1041 15.3768 54.1041 14.3681V6.54849C54.1041 5.8042 53.7629 5.41613 53.4217 5.27552V5.11991L55.4389 4.4375V14.3681Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.9444 12.289C56.9444 14.6003 58.4025 16.2762 60.7136 16.2766C63.0884 16.2762 64.6853 14.6171 64.6853 12.0884C64.6853 9.77681 63.3354 8.0539 60.9001 8.0539C58.4648 8.0539 56.9444 9.77494 56.9444 12.289ZM63.2117 12.3527C63.2117 14.5706 62.2499 15.8586 60.932 15.8586C59.3647 15.8586 58.4029 14.1844 58.4029 11.9497C58.4029 9.87055 59.3328 8.45885 60.6826 8.45885C62.2031 8.45885 63.2117 9.97929 63.2117 12.3527Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.5045 14.9587C72.1637 14.9587 72.9099 15.4236 72.9099 16.6178C72.9099 18.2001 71.0014 19.9849 68.3017 19.9849C66.455 19.9849 65.4314 19.1619 65.4314 18.0145C65.4314 17.1297 66.1457 16.3535 67.1075 16.0442C66.6107 15.8267 66.3313 15.4386 66.3313 14.9268C66.3313 14.1207 67.0606 13.5451 67.5105 13.2976C66.4719 12.9246 65.7876 12.0565 65.7876 10.8304C65.7876 9.23314 67.0906 8.05391 68.9991 8.05391C69.6965 8.05391 70.3171 8.22452 70.7989 8.53573C71.0782 7.77457 71.605 7.27964 72.2574 7.27964C72.7842 7.27964 73.0955 7.62084 73.0955 8.02392C73.0955 8.42699 72.848 8.7382 72.3981 8.7382C72.0418 8.7382 71.7775 8.52073 71.6688 8.21139C71.4213 8.25826 71.2038 8.42887 71.0332 8.69321C71.6069 9.15815 71.9331 9.84057 71.9331 10.6955C71.9331 12.3396 70.5833 13.4889 68.7066 13.4889C68.4123 13.4889 68.118 13.457 67.8536 13.3951C67.5893 13.6745 67.4037 13.9688 67.4037 14.2631C67.4037 14.7281 67.7299 14.9606 68.4742 14.9606H70.5064L70.5045 14.9587ZM68.3317 16.2466C68.0055 16.2466 67.7111 16.2148 67.4637 16.1529C66.9369 16.4941 66.5788 17.0209 66.5788 17.6733C66.5788 18.7757 67.4937 19.3962 68.8135 19.3962C70.4895 19.3962 71.8225 18.5732 71.8225 17.2084C71.8225 16.511 71.3726 16.2466 70.4408 16.2466H68.3317ZM67.1525 10.6917C67.1525 12.1653 67.8967 13.097 68.8585 13.097C69.8203 13.097 70.5645 12.1821 70.5645 10.8154C70.5645 9.44874 69.8502 8.42699 68.8585 8.42699C67.8667 8.42699 67.1525 9.37375 67.1525 10.6917Z"
      fill="black"
    />
    <path
      d="M73.8454 8.36511C74.4359 8.76818 74.7621 9.37373 75.2271 10.4124L75.2252 10.4142L77.7542 16.2316C76.9012 18.6838 74.8634 19.1188 73.3748 19.1956V19.3344C73.7329 19.7224 74.2597 20.0018 74.9271 20.0018C76.4625 20.0018 77.2987 18.367 78.4048 15.6223C79.5109 12.8758 80.2364 10.941 80.2364 10.941C80.8889 9.29687 81.3069 8.67632 81.9125 8.36511V8.22638H79.524V8.36511C80.1277 8.73631 80.1127 9.68307 79.6628 10.971L78.3748 14.5706L76.6838 10.5061C76.2657 9.49747 76.1101 8.78318 76.7156 8.36511V8.22638H73.8454V8.36511Z"
      fill="black"
    />
  </svg>
);

export default Logo;
