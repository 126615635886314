import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, MemoryRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { i18n, I18nThemeProvider } from '@bb-ui/i18n/dist';
import { AppConfigProvider } from 'contexts/AppConfigContext';
import { BbTenantProvider } from 'contexts/BbTenantContext';
import { PageTemplate } from 'components/PageTemplate';
import { ConnectionPicker } from 'pages/ConnectionPicker';
import { LoginParamsCheck } from 'pages/LoginParamsCheck';
import { SignIn } from 'pages/SignIn';
import * as serviceWorker from './serviceWorker';

i18n.init({
  ns: ['translation', 'links'],
  whitelist: [
    'ar', 'bg', 'ca', 'cs', 'cy', 'da', 'de', 'en', 'es', 'fi', 'fr', 'ga', 'he', 'it', 'ja',
    'ko', 'ms', 'nl', 'nn', 'nb', 'pl', 'pt', 'ru', 'sv', 'th', 'tr', 'vi', 'zh', 'mi',
  ],
});

// Use MemoryRouter in prod, since we do not want to expose route changes to the user.

const Router: React.ElementType = process.env.NODE_ENV === 'development' ? BrowserRouter : MemoryRouter;

/**
 * Last resort error message.
 * Keeping this simple since translations and bb-ui aren't available at this point.
 */
const ErrorFallback = ({ error }: { error?: Error }) => (
  <div role="alert">
    <h1>Uh oh. An error occurred. Please refresh the page or contact your system administrator for help.</h1>
    {error && <p>{error.message}</p>}
  </div>
);

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <React.Suspense fallback="">
      <I18nThemeProvider theme="light">
        <AppConfigProvider>
          <BbTenantProvider>
            <PageTemplate>
              <Router>
                <Switch>
                  <Route path="/" component={LoginParamsCheck} exact />
                  <Route path="/connection-picker" component={ConnectionPicker} />
                  <Route path="/sign-in/:idpId" component={SignIn} />
                </Switch>
              </Router>
            </PageTemplate>
          </BbTenantProvider>
        </AppConfigProvider>
      </I18nThemeProvider>
    </React.Suspense>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
